const g = "display: inline-block;font-size: 10px;color: #606060;font-family: Ubuntu Mono, monospace !important;text-rendering: optimizeSpeed;"
const r = "display: inline-block;font-size: 10px;color: #bb0e3d;font-family: Ubuntu Mono, monospace !important;text-rendering: optimizeSpeed;"
const nciLogo = `
%c.cKMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM? %c'bMQmc'        
%cpMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMv %c'aMMME-       
%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMf  %czMMMB:      
%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM9' %ctMMMQ]     
%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMg' %c=MMMM1    
%cMMMMM   'oMMMMM   MM   MM   MMMMMM   MMMMMMp. %c:BMMMz   
%cMMMMM     ?MMMM   MM   MM   MMMMMM   MMMMMMMN- %c,NMMM2' 
%cMMMMM   A  :#MM   MM   MM   MMMMMM   MMMMMMMMB: %c.dMMMD'
%cMMMMM   9M  .dM   MM   MM            MMMMMMMMMB. %c^MMMM2
%cMMMMM   9MM  'V   MM   MM   MMMMMM   MMMMMMMMB: %c.AMMMO'
%cMMMMM   9MMMb     MM   MM   MMMMMM   MMMMMMME- %c_WMMMf' 
%cMMMMM   9MMMME.   MM   MM   MMMMMM   MMMMMMm. %c:BMMM3   
%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM8' %c)QMMM?    
%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMw' %cvMMMQ[     
%cMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMML  %coMMMB-      
%cmMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMr %c'&MMMK_       
%c'uKMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM? %c'DMMAc'        
`

const Logo = () => console.log(nciLogo,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r,g,r)
export default Logo